/* styles.css */
.image {
  flex: 1 1 300px;
  text-align: center;
  padding: 10px;
}

.image img {
  width: 150px;
  height: 150px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text {
  flex: 1 1 300px;
  text-align: center;
  padding: 20px;
}

.text h2 {
  font-family: monospace;
  font-size: 2rem;
  margin-bottom: 16px;
}

.text p {
  font-family: monospace;
  font-size: 1rem;
}

.project-list {
  padding: 50px;
  flex: auto;
}

.project-card {
  width: auto;
  height: auto;
  padding: 20px;
  margin: 20px;
}